import HELPERS from "@/modules/OperatorPanel/helpers";
import Vue from "vue";
import axios from "../../axios-middleware";
import i18n from "@/lang/i18n.js";

const state = {
  operator_panel: {
    userInfo: null,
    allUsersList: [],
    fullUsersList: [],
    statuses: [],
		userSettingsJson: {
			reset_status: true,
			activate_auto_replies: false,
			notifications_browser_disabled: false,
			notifications_sound_enabled: false,
			system_notifications: {
				"chat_room_created": true,
				"new_message": true,
				"participants_connected": true,
				"chat_room_closed": true,
			},
		},
		message_delay: 0,
    soundOptions: {
      'None': 'Без звука',
      'Sunrise': '/sound_1.mp3',
      'Serenity':'/sound_2.mp3',
      'Radiance':'/sound_3.mp3',
      'Aurora':'/sound_4.mp3',
      'Harmony':'/sound_5.mp3',
      'Tranquility':'/sound_6.mp3',
      'Melody':'/sound_7.mp3',
      'Bliss':'/sound_8.mp3',
      'Zenith':'/sound_9.mp3',
      'Cascade':'/sound_10.mp3',
      'Celestial':'/sound_11.mp3',
      'Whisper':'/sound_12.mp3',
    },
		extended_dialog_display: 0,
		notificationsEnabled: false,
  },
};

const mutations = {
  OP_SET_USER_INFO(state, userInfo) {
    Vue.set(state.operator_panel, 'userInfo', userInfo);
  },
	OP_SET_USER_SETTINGS_JSON(state, userSettings) {
		for (let setting in userSettings) {
			Vue.set(state.operator_panel.userSettingsJson, setting, userSettings[setting]);

			//set chat_rooms_menu
			if (setting === 'chat_rooms_menu') {
				const settingFolders = userSettings[setting].folders
				const enumFolders = state.operator_panel.chatRoomFolders

				let enumMap = new Map(enumFolders.map(item => [item.key, item]));
				let combinedArray = settingFolders.map(item => {
					let enumItem = enumMap.get(item.key);
					return {
						...item,
						...(enumItem && { name: enumItem.name }), // Update customKey from enum
						...enumItem
					};
				});
				enumFolders.forEach(item => {
					if (!combinedArray.some(obj => obj.key === item.key)) {
						combinedArray.push(item);
					}
				});

				Vue.set(state.operator_panel, 'chatRoomFolders', combinedArray)
				Vue.set(state.operator_panel, 'chatRoomsSorting', userSettings[setting].sorting)
			}
		}
	},
	OP_SET_USER_SETTING(state, object) {
		const objectKey = Object.keys(object)[0]

		Vue.set(
			state.operator_panel.userSettingsJson,
			objectKey,
			object[objectKey]
		);
	},

  OP_SET_ALL_USERS_LIST(state, usersList) {
    state.operator_panel.allUsersList = usersList;
  },

	OP_SET_FULL_USERS_LIST(state, usersList) {
		state.operator_panel.fullUsersList = usersList;
	},

  OP_SET_STATUSES_LIST(state, statuses) {
    state.operator_panel.statuses = statuses;
  },

	OP_SET_MESSAGE_DELAY(state, delay) {
		state.operator_panel.message_delay = delay;
	},

	OP_SET_EXTENDED_DIALOG_DISPLAY(state, value) {
		state.operator_panel.extended_dialog_display = value;
	},

	OP_SET_NOTIFICATIONS_ENABLED(state, boolean) {
		state.operator_panel.notificationsEnabled = boolean;
	},
};

const actions = {
  async OpAxiosGetMyInfo({state, commit, dispatch }) {
		if(!Vue.$cookies.get('token_c')) return

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/user/info/`;

    const user = await axios
      .get(url)
      .then((response) => {
				commit("OP_SET_USER_INFO", response.data?.data?.user || null);
        return response.data?.data?.user || null;
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return null;
      });

		if (user?.settings_json) {
			if (user.settings_json?.activate_auto_replies === true) {
				dispatch('opSetRepliesMode', 'auto-replies');
			}
			commit("OP_SET_USER_SETTINGS_JSON", user.settings_json)

			// setDefault Folder
			const defaultFolder = state.operator_panel.chatRoomFolders
				.find(item => item.default)?.key

			if(
				defaultFolder
				&& state.operator_panel.typeChatRooms.type.length === 0
			) {
				dispatch('opSetTypeChatRooms', {
					type: defaultFolder,
					id: '',
				})
			}
			//end setDefault Folder
		}

    return user;
  },

	setUserSetting({ commit }, object) {
		commit('OP_SET_USER_SETTING', object)
	},

	async OpAxiosSaveUserSettings({  state, dispatch }) {
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/user/update_settings/`;
		let settings = state.operator_panel.userSettingsJson

		//save Menu Chat Rooms settings
		settings['chat_rooms_menu'] = {
			folders: state.operator_panel.chatRoomFolders,
			sorting: state.operator_panel.chatRoomsSorting,
		}

		const request = {
			settings_json: JSON.stringify(settings)
		};

		await axios
			.post(url, request)
			.then(() => {
				dispatch('OpAxiosGetMyInfo')
			})
			.catch((err) => {
				HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
				return [];
			});
	},

  async opGetAllUsersList({ commit, state, getters }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/users/list/`;

    const userList = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.users || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return [];
      });

		//Save full users List
		commit("OP_SET_FULL_USERS_LIST", userList);
		//end Save full users List

		//change on getting data from getter, before was endpoint
		const opSettings = getters.opIsSettings
		const connectOperatorFromOtherSkillGroup = opSettings.find(s => s.key === 'connect_second_operator_from_other_skill_group')

    if (connectOperatorFromOtherSkillGroup?.value) {
      if (+connectOperatorFromOtherSkillGroup.value) {
        commit("OP_SET_ALL_USERS_LIST", userList);
      } else {
        setTimeout(() => {
          const data = userList.filter(u => {
            if (u?.skill_groups?.length) {
							let array = []

              u.skill_groups.map(s => {
                s.subjects.map(item => {
									if (item?.id === (getters.opActiveChatGroup?.id || state.operator_panel?.activeChatGroup) ) {
										array.push(item)
									}
								})
              })

              if (array?.length) {
                const common = u?.kw_user?.role?.common

                if (typeof common === 'string') {
                  JSON.parse(u?.kw_user?.role?.common);
                }

                return u;
              }
            }
          })

          commit("OP_SET_ALL_USERS_LIST", data);
        }, 0)
      }
    }
  },

  async opGetStatusesList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/statuses/list/`;

    const statuses = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.statuses || [];
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return [];
      });

    commit('OP_SET_STATUSES_LIST', statuses);
  },

  async opSetUserStatus({ dispatch }, data) {
    dispatch("updateAjaxDialog", [true, true]);

    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/user/update_own_availability/`;
    const request = data;
    const success = await axios
      .post(url, request)
      .then((response) => {
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.status === "success";
      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_get')]);
        return false;
      });

    return success;
  },

	OpSetNotificationsEnabled({commit}, boolean) {
		commit('OP_SET_NOTIFICATIONS_ENABLED', boolean)
	},

};

const getters = {
  opUserInfo: (state) => state.operator_panel.userInfo,
  opUserSettingsJson: (state) => state.operator_panel.userSettingsJson,

  opAllUsersList: (state) => {
    const users = state.operator_panel.allUsersList;
    const setName = (user) => {
			user.name = HELPERS.formatUserName(user) || `${this.$t('modules.op.texts.settings_saved_error')} (id ${user.id})`;
    };

    users.forEach(setName);

    return users;
  },
	opFullUsersList: (state) => {
		const users = state.operator_panel.fullUsersList;
		const setName = (user) => {
			user.name = HELPERS.formatUserName(user) || `${this.$t('modules.op.texts.settings_saved_error')} (id ${user.id})`;
		};

		users.forEach(setName);

		return users;
	},

  opStatusesList: (state) => {
    return state.operator_panel.statuses;
  },
	opGetMessageDelay: (state) => state.operator_panel.message_delay,

  soundOptions: state => state.operator_panel.soundOptions,

	opGetExtendedDialogDisplay: (state) => state.operator_panel.extended_dialog_display,

	opGetNotificationsEnabled: state => state.operator_panel.notificationsEnabled
};

export default {
  state,
  mutations,
  actions,
  getters,
};
